<template>
  <div class="container">
    <div class="static-page" style="margin-bottom: 50px;">
      <h1 class="title">АГЕНТСКИЙ ДОГОВОР-ОФЕРТА ООО «АЛАМАТ»</h1>
      <p>г. Москва</p>
      <p>Дата акцепта:</p>
      <p>Настоящий документ является официальным предложением Общества с ограниченной ответственностью «АЛАМАТ»,
        адресованным российским юридическим лицам и индивидуальным предпринимателям, зарегистрированным в
        установленном законом порядке и осуществляющим предоставление услуг по временному размещению (проживанию)
        клиентов на территории Российской Федерации (далее – Отель), заключить агентский договор на
        нижеперечисленных условиях.
        <br>В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации настоящий документ
        является публичной офертой.
        <br>Настоящий агентский договор-оферта (далее – Договор) заключается в особом порядке: путем акцепта
        Принципалом настоящей публичной оферты, содержащей все существенные условия Договора, без подписания
        сторонами. Настоящий Договор имеет юридическую силу в соответствии со ст. 434 Гражданского Кодекса
        Российской Федерации и является равносильным договору, подписанному сторонами.
        <br>Настоящий договор является Договором присоединения. Фактом, подтверждающим полное и безоговорочное
        принятие изложенных ниже условий, и акцептом настоящей публичной оферты является нажатие Принципалом кнопки
        «Акцепт», расположенной в конце настоящего Договора.
        <br>Настоящий Договор между Агентом и Принципалом считается заключенным в момент получения Агентом Акцепта
        Принципала. Акцепт считается полученным Агентом в момент активации чекбокса установлением флажка об
        ознакомлении и согласии с условиями настоящего Договора-оферты на этапе регистрации.
        <br>Агент и Принципал далее совместно именуются «Стороны», а по отдельности – «Сторона».
        <br>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
        <br>1.1. «Бронирование» означает совокупность действий Пользователя, в результате которой на Сайте
        Пользователем был оформлен заказ на Услуги Принципала, а также в рамках которой Пользователь оплатил или
        обязался оплатить стоимость заказа предложенным на Сайте и/или озвученным Клиентским сервисом Агента
        способом.
        <br>1.2. «Вознаграждение Агента» означает денежные средства, причитающиеся Агенту по Договору.
        <br>1.3. «Гостиница» («Пансионат», «Санаторий», «База отдыха», «Гостевой дом», «Хостел», «Жилое помещение» и
        пр.) означает любые помещения, согласованные Сторонами в Договоре, в которых Принципал предлагает размещение
        (проживание), а также, в зависимости от типа помещения, прочие связанные с этим услуги Гостям через Сайты.
        Стороны согласились далее по тексту использовать общий термин «Гостиница» в качестве наименования места
        размещения (проживания) Гостя независимо от предполагаемого места его фактического размещения (проживания)
        по Договору о размещении.
        <br>1.4. «Гостиничные услуги» означают оказываемые Принципалом услуги по размещению (проживанию) в Номерах
        Гостиницы, их аренде, коммерческому найму, а также иные услуги, предлагаемые Принципалом Пользователям
        посредством Сайта, в зависимости от типа помещения.
        <br>1.5. «Гость» означает Пользователя, заключившего Договор о Бронировании Гостиничных услуг.
        <br>1.6. «Договор о Бронировании Гостиничных услуг» означает договор (договор оказания услуг, договор
        аренды, договор коммерческого найма и др.), который заключается между Агентом и Гостем при Бронировании
        Гостем размещения и/или прочих услуг у Гостиницы через Сайты и/или Клиентский сервис Агента.
        <br>1.7. «Договор» означает Агентский договор-оферту, заключенный между Агентом и Принципалом, а также любые
        прочие приложения и дополнения к нему, заключенные Сторонами.
        <br>1.8. «Информация» означает содержательное описание Номеров, Услуг Гостиницы, размещаемых
        посредством Системы или иного способа управления, передачи и сообщения Информации Агенту, в том числе
        название, текстовое описание, изображения, графические материалы, ремарки, мультимедиа, а также цена с
        учетом всех налогов, количество единиц предлагаемых Номеров и виды Услуг, доступные для Бронирования в
        указанные Принципалом даты. Информация также включает условия отмены и/или изменения Бронирования (если
        применимо).
        <br>1.9. «Номер» означает помещение в Гостинице (а в применимых случаях также дом (коттедж) и их части,
        апартаменты, кровать/койко-место в общих помещениях, жилой дом, часть жилого дома, квартиру, часть квартиры,
        комнату и прочее), которое является объектом Бронирования.
        <br>1.10. «Общая сумма успешных Бронирований» означает общую сумму Стоимости Бронирований по Предоплатному
        тарифу, выезд Гостей по которым состоялся (должен был состояться) в отчётном календарном месяце, и штрафов
        за нарушение политики отмены Бронирований, выезд по которым должен был состояться в отчётном календарном
        месяце (в случае наличия такового), за вычетом суммы отмененных Бронирований, а также за вычетом
        Вознаграждения Агента.
        <br>1.11. «Пользователь» означает физическое лицо, являющееся посетителем информационных ресурсов в сети
        Интернет, в том числе посетителем Сайта.
        <br>1.12. «Сайт» означает информационный ресурс в сети Интернет, размещенный по адресу https://alamat.ru/.
        <br>1.13. «Стоимость Бронирования» означает стоимость Бронирования Гостиничных услуг, по которой заключен
        (предлагается к заключению) Договор о Бронировании Гостиничных услуг.</p>
      <h1 class="title">2. ПРЕДМЕТ ДОГОВОРА</h1>
      <p>2.1. По настоящему Договору Агент обязуется за Вознаграждение совершать юридические и фактические действия,
        в том числе:
        <br>2.1.1. Предоставлять Принципалу доступ к технологической системе бронирования отелей (далее по тексту –
        Система), посредством использования которой Принципал приобретает возможность размещать Информацию о Принципале
        (Гостинице) на Сайте (либо на иных интернет-сайтах Агента и интернет-сайтах аффилированных лиц и контрагентов,
        включая интернет-сайты третьих лиц) в целях продвижения услуг Принципала.
        <br>2.1.2. Заключать Договоры о Бронировании Гостиничных услуг с Гостями, а также договоры об оказании услуг
        по проживанию, размещению, договоры оказания гостиничных услуг от своего имени, но за счет Принципала.
        <br>2.1.3. Получать и обрабатывать персональные данные Пользователей.
        <br>2.1.4. Осуществлять комплекс технических и организационных мер по поддержанию работоспособности Сайта.
        <br>2.2. Агент также вправе привлекать Пользователей и иных лиц на Сайт в целях продвижения услуг Принципала
        посредством интернет-маркетинга, рассылки информационных писем и/или рекламы в поисковых системах, а также
        всеми иными способами, не противоречащими российскому законодательству.
        <br>2.3. Агент вправе в целях исполнения настоящего Договора заключать субагентские и иные договоры с
        третьими
        лицами, оставаясь ответственным за действия таких лиц перед Принципалом.
        <br>2.4. Тип (категория), количество и иная информация о Номерах, подлежащая размещению на Сайте, указывается
        Принципалом. Принципал должен предоставить для размещения на Сайте Агента Номера любой категории, имеющиеся у
        Принципала в наличии, включая последний доступный для Бронирования Номер при условии, что такой Номер доступен
        для бронирования в других открытых источниках, включая веб-сайт Принципала (Гостиницы).</p>
      <h1 class="title">3. ПОРЯДОК РАСЧЕТОВ И ОТЧЕТНЫЕ ДОКУМЕНТЫ</h1>
      <p>
        3.1. Принципал устанавливает Предоплатный тариф, в соответствии с которым Гость оплачивает полную
        Стоимость
        Бронирования на Сайте Агенту. Агент самостоятельно удерживает причитающееся ему Ежемесячное Вознаграждение
        из сумм, поступивших от Гостей.
        <br>3.2. Принципал загружает в Систему стоимость Гостиничных услуг, выраженную в российских рублях и
        включающую
        ставку Вознаграждения Агента, включая НДС (в случае, если Принципал является плательщиком НДС) и иные налоги
        и сборы (в случае наличия таковых).
        <br>3.3. За выполнение поручений по настоящему Договору Агенту причитается Вознаграждение, равное 15,0% от
        Стоимости Бронирования.
        <br>3.4. Вознаграждение Агента взимается за успешные Бронирования и удержанные штрафы.
        <br>3.5. Агент также вправе время от времени предоставлять Принципалу возможность загружать стоимость
        Гостиничных услуг без учета Вознаграждения Агента (т.н. Net Rate). В случае если Принципал загружает
        стоимость Гостиничных услуг без учета Вознаграждения Агента (Net Rate), Вознаграждение Агента составляет
        разницу между Стоимостью Бронирования и Net Rate.
        <br>3.6. Агент вправе заключить Договор о Бронировании Гостиничных Услуг с Гостем на условиях более
        выгодных,
        чем указаны Принципалом; при этом дополнительная выгода принадлежит Агенту в полном объеме и является
        неотъемлемой частью Вознаграждения Агента.
        <br>3.7. Вознаграждение Агента рассчитывается исходя из стоимости Гостиничных услуг, загруженных Принципалом
        с
        учетом НДС.
        <br>3.8. Принципал обязан произвести сверку данных, указанных в Системе в течение 5 (пяти) рабочих дней с
        даты
        окончания календарного месяца (Период Сверки). В случае невозможности проведения сверки непосредственно
        через интерфейс Системы Принципал обязуется уведомить Агента о необходимых изменениях в Бронированиях
        посредством электронной почты в течение Периода Сверки. При наличии замечаний/претензий Принципал обязуется
        предоставить Агенту мотивированную претензию в течение Периода Сверки. В случае если в указанный срок
        Принципал не предоставит мотивированную претензию или уведомление об изменении Бронирований на электронную
        почту Агента, список Бронирований за отчетный период считается утвержденным Принципалом без замечаний.
        <br>3.9. Не позднее 5 (пяти) рабочих дней с даты окончания Периода Сверки Агент предоставляет Принципалу
        посредством Системы отчет Агента о совершенных Бронированиях (далее – Отчет), а также Универсальный передаточный
        документ (по форме, утвержденной Письмом ФНС России от 21.10.2013 № ММВ-20-3/96) (далее – УПД).
        <br>3.10. Не позднее 5 (пяти) рабочих дней с даты окончания срока, указанного в п. 2.8 Договора, Стороны
        осуществляют расчеты причитающихся им сумм в соответствии с настоящим Договором в следующем порядке:
        <br>3.10.1. Агент перечисляет на расчетный счет Принципала разницу между Общей суммой успешных Бронирований
        и
        размером Ежемесячного Вознаграждения Агента.
        <br>3.11. Оформление Отчета и УПД осуществляется в электронном виде посредством Системы. Оформление Отчета и
        УПД
        на бумажном носителе возможно по желанию Принципала при условии соответствующего уведомления Принципалом
        Агента, а также посредством электронного документооборота по согласованию сторон.
        <br>3.12. Системы, бухгалтерские документы и записи Агента (включая Систему, факс и/или электронную почту)
        считаются достаточным доказательством наличия и получения Принципалом (Гостиницей) Бронирования Гостя, а
        также суммы Вознаграждения, причитающегося Агенту по настоящему Договору.
        <br>3.13. Понесенные Агентом при осуществлении действий, предусмотренных пп. 1.3 – 1.4 настоящего Договора,
        затраты являются расходами Агента.</p>
      <h1 class="title">4. ПОРЯДОК ВСТУПЛЕНИЯ В СИЛУ И РАСТОРЖЕНИЯ ДОГОВОРА</h1>
      <p>
        4.1. Настоящий Договор вступает в силу в момент получения Агентом Акцепта Принципала и действует в
        течение 1
        (одного) календарного года. В случае если ни одна из Сторон не менее чем за 30 (тридцать) календарных дней
        до окончания срока действия Договора не заявит о желании его прекратить, Договор считается автоматически
        пролонгированным на следующий календарный год. Количество пролонгаций Договора при этом не ограничено.
        <br>4.2. Заключив настоящий Договор, Принципал подтверждает получение, ознакомление и безоговорочное
        согласие со
        всеми условиями настоящего Договора.
        <br>4.3. В случае если Принципал теряет право распоряжаться Номерами Гостиницы, он обязан незамедлительно
        сообщить об этом Агенту. При этом настоящий Договор считается расторгнутым в дату получения Агентом такого
        уведомления, а при отсутствии уведомления – в дату, когда Агент узнал об утере Принципалом права
        распоряжаться Номерами. Бронирования, совершенные до этого момента, должны быть обработаны Принципалом в
        соответствии с настоящим Договором.</p>
      <h1 class="title">5. ОТВЕТСТВЕННОСТЬ СТОРОН. НЕЗАЕЗД. ОВЕРБУКИНГ. КИКАУТ</h1>
      <p>
        5.1. За неисполнение или ненадлежащее исполнение обязательств Стороны по настоящему Договору несут
        ответственность, предусмотренную действующим законодательством Российской Федерации (далее – РФ), настоящим
        Договором и приложениями к нему.
        <br>5.2. Принципал несет ответственность за правильность, актуальность, полноту и соответствие действующему
        законодательству РФ Информации, переданной для публикации на Сайте посредством Системы или иного способа
        управления, передачи и сообщения информации о Гостинице (Принципале), в том числе в отношении стоимости
        Бронирования Номеров, их доступности для бронирования на Сайте, штрафов за изменение и/или отмену
        Бронирования, незаезд и т. п. Агент не несет ответственности за ошибки Принципала, возникшие при внесении,
        изменении, обновлении Информации на Сайте посредством Системы или иного способа управления, передачи и
        сообщения информации о Гостинице (Принципале), а также за ошибки на Сайтах третьих лиц.
        <br>5.3. В случае предъявления претензий/исков в отношении указанной Принципалом информации и/или
        предоставления Гостиничных услуг до момента или в процессе их реализации Агент разрешает возникшие споры и
        разногласия с Гостем, при этом Принципал может быть привлечен к разрешению претензий/исков по желанию Агента
        и/или Принципала или решению компетентного органа. Принципал возмещает все убытки, возникшие у Агента в
        связи с ненадлежащим оказанием Гостиничных услуг и/или предоставленной Принципалом информации, включая
        наложенные на Агента в связи с этим штрафы и выплаченные Клиенту денежные средства.
        <br>5.4. Правило о начислении процентов за пользование денежными средствами (законных процентов),
        установленное ст. 317.1 ГК РФ, неприменимо к отношениям Сторон, урегулированным настоящим Договором.
        <br>5.5. Принципал обязан уведомить Агента о незаезде Гостя в Гостиницу путем изменения статуса Бронирования
        посредством Системы не позднее 2 (двух) рабочих дней с запланированной даты прибытия Гостя.
        <br>5.6. В случае незаезда Гостя в Гостиницу Агент возмещает Гостю Стоимость Бронирования за вычетом суммы
        штрафа за незаезд, указанного Принципалом в Системе (при наличии такого штрафа). При этом Общая сумма успешных
        Бронирований за текущий месяц подлежит уменьшению на сумму, выплаченную в соответствии с настоящим пунктом
        Гостю. При недостаточности Общей суммы успешных Бронирований за текущий месяц Агент вправе уменьшить Общую сумму
        успешных Бронирований за следующий месяц или выставить Принципалу счет на сумму недостающей разницы; Принципал
        обязуется оплатить такой счет в течение пяти рабочих дней с даты получения.
        <br>5.7. Вознаграждение причитается Агенту, в том числе в случае невозможности заселения Гостя в
        забронированный Номер вследствие действия/бездействия Принципала, включая ситуации, когда о такой
        невозможности заселения стало известно заранее (овербукинг), а также в случае отказа Принципала заселить
        Гостя по факту прибытия в Гостиницу (кикаут).
        <br>5.8. В случае незаселения Гостя вследствие действия/бездействия Гостя Агенту причитается Вознаграждение,
        рассчитываемое исходя из сумм, выплаченных Гостем в соответствии с условиями Бронирования (например, штраф
        за нарушение условий Бронирования) (при наличии таковых), независимо от фактического взыскания Принципалом
        таких сумм с Гостя.</p>
      <h1 class="title">6. РАЗРЕШЕНИЕ СПОРОВ</h1>
      <p>
        6.1. Отношения Сторон по настоящему Договору регулируются нормами действующего законодательства РФ.
        <br>6.2. Претензионный порядок разрешения споров обязателен. В случае невозможности разрешения спора в
        претензионном порядке спор подлежит разрешению в Арбитражном суде г. Москвы.</p>
      <h1 class="title">7. ИНФОРМАЦИЯ</h1>
      <p>1.1. Принципал гарантирует Агенту, что обладает исключительным правом на Информацию, а также средства
        индивидуализации Принципала/Гостиницы (товарный знак/знак обслуживания, логотип и т. д.) и иные результаты
        интеллектуальной деятельности (далее – Объекты интеллектуальной собственности), загруженные в Систему или иным
        образом переданные Принципалом Агенту, или владеет ими на ином законном основании с правом предоставления таких
        Объектов интеллектуальной собственности Агенту в соответствии с настоящим Договором.
        <br>1.2. Принципал предоставляет Агенту право использовать Объекты интеллектуальной собственности любым
        способом для выполнения Агентом своих обязательств по Договору. Принципал предоставляет Агенту указанное
        право (лицензию) на весь срок действия Договора на безвозмездной основе на условиях неисключительной
        лицензии на территории всех стран мира, а также предоставляет согласие на передачу указанных прав в порядке
        сублицензии партнерам Агента и его аффилированных лиц.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>
